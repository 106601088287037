 
/* Small screens - MOBILE */
@media only screen {



} /* Define mobile styles - Mobile First */
 
@media only screen and (max-width: 40em) {

.left-side {
    height: auto !important;
}
#logo {
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-top: 5px;
}
.right-side {
    height: auto !important;
}

.header-widget {
    padding: 0px;
    border-top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    & .sign-in{
        flex: 0;
        top: 0;
        margin-right: 15px;
    }
     span{display: none;} 
    &  a.button.border{
        padding: 3px 5px ;
        box-shadow: none;
       
    }

}
#banner-two{
    background-position: 55%
}
.banner-text {
     padding: 0 1.2em; 
    text-align: left;
     h1.title {
    font-size: 2em;
    }
     h3 {
    font-size: 1em;
    color: #333;
    margin:0;
    }
}

.cat-list.row.no-gutters{
    margin-right: 15px !important; 
    margin-left: 15px !important;
}

a.cat-list__link {
    display: flex;
    align-items: center;
}
.cat-list__item {
    min-height: 4.5rem;
    padding: .5rem 0;
    width: 100%;
}
.cat-list__text {
    line-height: 1.2;
    font-size: 14px;
}

.cat-list__item--even {
    margin:2px;
    border: 1px solid #f4f4f4;
    width: 100%;
}
.mainCat:nth-child(even) > .cat-list__item--even {border-left: 0; margin-left: 2px; }
.featured-top h3{
    text-align: center;
    margin-bottom: .8em;
    width: 100%;
    font-size: 1.6em
}
.recommended-ads .quick-item {
    min-height: 220px;
    box-shadow: none;
    border: 1px solid #e0e0e0;

.ad-image {
    height: auto;
}

 .item-image img {
        min-height: auto;
    }
.item-info, .ad-info {
    min-height: auto;
}
.ad-info {
    min-height: auto;
    padding: 10px
}
.ad-info .item-price {
    font-size: 1.2em;
}
.item-title,.ad-meta{ margin:0; }
}

.footer-mobile {
    background-position: center;
}

.modal-container>.inner {
    position: absolute;
    left: 0;
    margin-left: 0;
    border-radius: 2px;
    top: 0;
    z-index: 100;
    background: #fff;
    width: 100vw;
    height: 100vh;
    box-shadow: 0 0 40px rgba(0,0,0,.2);
}

#loginPopUp .modal-content {
    padding: 2em;
    background-color: transparent;
    border: none;
    box-shadow: none;
    position: relative;
    top: 2em;
}

/*******ad_detail.tpl**********/


#main .ad-details h3.title {
    font-size: 1.5em;
    font-weight: normal;
    line-height: 1em
}

.scroll-title{
    background-color: rgba(0,0,0,.9);
    padding: .4em 1em;
    display: none;
    justify-content:center;
    align-items: center;
    text-align: center;
    strong {
        color: white;
        font-size: 1.3em
    }
    .detail-corner-icon {
    position: relative;
    top: 5px;
    right: inherit;
    }
}
#main.fixed > .scroll-title{ display: flex; }

.ad-details span.icon{
margin:0;
    margin-top:.5rem;
}
.slider {
    background-color: #fff;
    padding: 1em;
    border-radius: 4px;
    margin-top: .5em;
    box-shadow:none;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
}

.ad-details{
    margin-top: .8em
}
.carousel-inner>.item{
    height: 100%;
    line-height: normal;
}
.carousel-inner {
    border: 0;
    img{
        border-radius: 5px;
    }
    }
#product-carousel .carousel-indicators li img {
    border-radius: 2px;
    border: 2px solid transparent;
}
#product-carousel .carousel-indicators li, #product-carousel .carousel-indicators li.active{
    width: 5em;
    margin: 0 0 0 0px;
}
#product-carousel .carousel-control{
    font-size: 2em;
    opacity: .7;
    }
.detail-title{ display: none;}
.description{ padding-top: 1em; margin-bottom: 0;}

.fixed-contact{
    padding: 1em;
    background:#f8f9fa;
    text-align: center;
    border-top: 1px solid #e5e5e5;
}

/*******zap******/
.wrapper {
    position: relative;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin: 0 0 2em 0;
}
    .heading h1{
        font-size: 1.8em;
    }

.tvservice-type:first-child {
    margin-right: 1em;
}
.tvservice-type {
    height: 6em;
    position: relative;
    width: 100%;
     label{
        background-size: contain
    }
}
.tvservice-types input:checked+label {
    outline: 4px solid #409fff;
}
.amount {
    h3.mb-1{
        font-size: 1.3em
    }
    .h2, h2 {
    font-size: 1.8em;
}
}
.g-mb__body{
    padding-left: 15px;
}
.has_removed{
    .btn-pay{
        width: 100%;
    }
}
.bg-custom {
    box-shadow: inset 0 1px 0 0 #e0e6ed;
}
.bookme-progress.big-ld {
    min-height: 25vh;
}

/**********listing*******/
.quick-item .ad-listing .image {
    width: 120px;
   position: relative;
}

.quick-item .ad-listing {
    position: relative;
    overflow: hidden;
    height: auto; 
    width: 100%;
    padding-left: 0;
    display: flex;
}
.ad-info .item-title {
    font-size: 16px;
    width: 85%;
}

.quick-item .ad-listing .item-price {
    margin-top: 0;
}

.recommended-ads .allAd .quick-item {
    border: 1px solid #f3f3f3;
    margin: 5px 0;
    min-height: auto;
    border-radius: 5px;
    max-height: 120px;
    overflow: hidden;
}

 /* max-width 640px, mobile-only styles, use when QAing mobile issues */

}
 
/* Medium screens - TABLET */
@media only screen and (min-width: 40.063em) and (max-width: 64em){

#header {
    padding: 0;
    font-size: 16px;
}

#logo {
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-top: 5px;
    text-align: left;
    vertical-align: middle;
    padding-top: 20px; 
    img {
    max-height: 55px;
}
}

.header-widget {
    border-top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    & .sign-in{
        top: 0;
        margin-right: 15px;
    }
    &  a.button.border{
        font-size: .9em;
        padding: .5em .8em ;
        box-shadow: none;
       
    }

}
.banner-text {
     padding: 0 1.2em; 
    text-align: left;
     h1.title {
    font-size: 2em;
    }
     h3 {
    font-size: 1.2em;
    color: #333;
    margin:0;
    }
}


} /* min-width 641px, medium screens */
 
@media only screen and (min-width: 40.063em) and (max-width: 64em) { } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
 
/* Large screens - DESKTOP */
@media only screen and (min-width: 64.063em) { } /* min-width 1025px, large screens */
 
@media only screen and (min-width: 64.063em) and (max-width: 90em) { } /* min-width 1024px and max-width 1440px, use when QAing large screen-only issues */
 
/* XLarge screens */
@media only screen and (min-width: 90.063em) { } /* min-width 1441px, xlarge screens */
 
@media only screen and (min-width: 90.063em) and (max-width: 120em) { } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */
 
/* XXLarge screens */
@media only screen and (min-width: 120.063em) { } /* min-width 1921px, xlarge screens */